import React from 'react'
import styled from 'styled-components'
import parseList from '../../utils/parse-list'
import { transformToId } from '../../utils/links'

const Container = styled.div`
  padding: 12px;
  margin: 0 0 24px 0;
  background-color: #e9f3f7;
  border: solid 1px #d6f0ff;
  border-radius: 6px;
  font-size: small;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 6px 0 0 0;
    font-size: small;

    li {
      display: inline-block;
      margin: 0;
      padding: 0;
    }

    li + li {
      margin-left: 1em;
    }
    a:hover {
    text-decoration: underline !important
}

  }
`
const OtherVersions = ({ frontmatter, slug }) => {

  if (!frontmatter.versions) return ''

  const versions = [ ...parseList(frontmatter.versions) ]
  const regex = /^(.*?)(?:cmcc-\d+\/)?$/;
  const matchResult = slug.match(regex);
  let prefix = ""
  if (matchResult && matchResult.length >= 2) {
    prefix = matchResult[1];
  } else {
    console.log("No match found.");
  }
  return (
    <Container>
      This guide has multiple versions
      <ul>
        {versions.map((version, index) => (
          <li key={index}>
            {version !== frontmatter.version
              ? (<a href={`${index !== 0 ? "/" + prefix + transformToId(version) : "/" + prefix}`}>{version}</a>)
              : (<strong>{version}</strong>)}
          </li>
        ))}
      </ul>
    </Container>
  )
}

export default OtherVersions
