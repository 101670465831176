import React from 'react'
import styled from 'styled-components'
import {Col, Row} from 'react-grid-system'
import userFemale from './laptop user_female_01_blue.svg'
import userMale from './laptop user_male_01_blue.svg'
import lightbulb from './lightbulb_blue.svg'
import watch from './watch_blue.svg'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import parseList from '../../utils/parse-list'

dayjs.extend(relativeTime)

const Style = styled.div`
  margin: 0 0 4rem 0;
  
  h3.title {
    display: flex;
    align-items: center;
    font-weight: 300;
    padding: 0;
    margin: 1rem 0;
    
    > * + * {
      padding-left: 12px;
    }

    img {
      width: 72px;
      height: 72px;
      margin: 0;
    }
    
    span {
      padding-bottom: .3rem;
      margin-top: 1rem;
      display: block;
      flex-grow: 1;
    }
  }
  
  ul {
    li {
      margin: 0 0 .5rem 0;
    }
  }
  
  .dates {
    font-size: 14px;
    text-transform: uppercase;
    color: #787878;
    line-height: 150%;
    margin-bottom: 24px;
  }
  
  .alert {
    margin: 24px 0;
  }
`

const ArticleHeader = ({ frontmatter, toc, createdGmt, modifiedGmt }) => {
  // All meta must exist
  if (!frontmatter || !frontmatter.goals || !frontmatter.prerequisites || !frontmatter.audience || !frontmatter.worktime) {

    if (process.env.NODE_ENV === 'development') {
      if (frontmatter) {
        console.warn('Did not render frontmatter block. Not all data is available. Goals: ',
          frontmatter.goals, '. Prerequisites: ', frontmatter.prerequisites, '. Audience: ',
          frontmatter.audience, '. Worktime: ', frontmatter.worktime)
      } else {
        console.warn('Did not render frontmatter block, because frontmatter is not defined ')
      }
    }
    if (frontmatter.title) {
      return (
          <Style>
            <Row>
              <Col>
                <h1>{frontmatter.title} {frontmatter.version ? <strong>- {frontmatter.version}</strong> : ''}</h1>
                <div className={'dates'}>
                  Last updated {dayjs(modifiedGmt).fromNow()}
                </div>
                {toc}
                <p className={'abstract'}>{frontmatter.abstract}</p>
              </Col>
            </Row>
          </Style>
      )

    }
    return''
  }

  const audienceList = parseList(frontmatter.audience)
  const goalsList = parseList(frontmatter.goals)
  const prerequisitesList = parseList(frontmatter.prerequisites)

  return (
    <Style>
      <Row>
        <Col>
          <h1>{frontmatter.title} {frontmatter.version ? <strong>- {frontmatter.version}</strong> : ''}</h1>
          <div className={'dates'}>
            Last updated {dayjs(modifiedGmt).fromNow()}
          </div>
          {toc}
          <p className={'abstract'}>{frontmatter.abstract}</p>
        </Col>
      </Row>
      {frontmatter.is_outdated && (
        <Row>
          <Col>
            <div className={'admonitionblock alert'}>
              <p>{frontmatter.version
                ? <strong>This guide applies to {frontmatter.version}</strong>
                : <strong>Outdated</strong>}</p>
              <p>
                This how-to guide has yet to be updated for the most recent product release.
                Some of the elements in this guide might not work as described, if you are
                on the latest version.
              </p>
              <p>
                <em>Thank you for your patience!</em>
              </p>
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Col xl={6} className={'info'}>
          <h3 className={'title'}>
            <img src={lightbulb} alt={'Lightbulb'}/>
            <span>What you'll learn</span>
          </h3>
          <ul>
            {goalsList.map((goal, index) => (
              <li key={index}>{goal}</li>
            ))}
          </ul>
        </Col>
        <Col xl={6} className={'info'}>
          <h3 className={'title'}>
            <img src={userFemale} alt={'Person reading a book'} />
            <span>Prerequisites</span>
          </h3>
          <ul>
            {prerequisitesList.map((prereq, index) => (
              <li key={index}>{prereq}</li>
            ))}
          </ul>
        </Col>
      </Row>
      <Row>
        <Col xl={6} className={'info'}>
          <h3 className={'title'}>
            <img src={watch} alt={'Wristwatch'} />
            <span>Time matters</span>
          </h3>
          <div>
            <span>{frontmatter.worktime}</span>
          </div>
        </Col>
        <Col xl={6} className={'info'}>
          <h3 className={'title'}>
            <img src={userMale} alt={'Person in front of a laptop'} />
            <span>Should I read this?</span>
          </h3>
          <div>
            <span>This guide is for {audienceList.join(', ')}.</span>
          </div>
        </Col>
      </Row>
    </Style>
  )
}

export default ArticleHeader