import {Link as ScrollLink} from "react-scroll";
import {headlineToId} from "../../utils/links";
import React from "react";
import styled from "styled-components";
import clsx from "clsx";
import OtherVersions from '../OtherVersions'

const Style = styled.div`
  list-style-type: none;
  margin: 0 0 48px 0;
  font-size: 0.9rem;
  padding: 0 12px 0 48px;
  z-index: 10;
  max-height: calc(100vh - 104px);
  width: 18rem;

  position: fixed;
  overflow-y: auto;

  ul {
    margin-left: 0;
  }

  &.inline {
    position: unset;
    max-height: none;
    padding: 0;
    width: 100%;
  }

  .toc-title {
    text-transform: uppercase;
    color: #787878;
    font-size: 0.95rem;
    margin-bottom: 12px;
    font-weight: bold;
  }

  .toc {
    list-style-type: none;
  }

  .active a {
    text-decoration: underline;
    font-weight: 500;
  }

  a, a:hover, a:visited, .article a {
    cursor: pointer;
    color: #868686 !important;
    text-decoration: none !important;
  }

  .depth-2 {
    margin-left: .75rem;
  }

  .depth-3 {
    font-size: 0.9rem;
    margin-left: 1.5rem;
  }

  .depth-4, .depth-5, .depth-6 {
    font-size: 0.8rem;
    margin-left: 2rem;
  }
`;

const Toc = ({toc, inline = false, frontmatter, slug}) => {

  if (!toc || toc.length <= 1) return (
    <Style className={clsx({ inline, haha:'hahah' })}>
      <OtherVersions frontmatter={frontmatter} slug={slug}/>
    </Style>
  )

  const items = toc.map((item) => ({...item, id: headlineToId(item.value)}))

  return (
    <Style className={clsx({ inline })}>
      <OtherVersions frontmatter={frontmatter} slug={slug} />
      <div className={'toc-title'}>Table of Contents</div>
      <ul className={'toc'}>
        {items.map((item, index) => {
          return (
            <li key={index} className={`depth-${item.depth}`} >
              <ScrollLink to={item.href ? item.href : item.id}
                          smooth={true}
                          offset={-130}>{item.value}</ScrollLink>
            </li>
          )
        })}
      </ul>
    </Style>
  )
}

export default Toc
